@import "./styles/Masonry.css";
@import "./styles/grid.css";
@import "./styles/SideMenu.css";

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url("/images/HomePage2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

h2 {
  margin: 0;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0px;
}

p {
  color: white;
  font-size: x-large;
}

li {
  cursor: pointer;
  vertical-align: top;
}

.nav {
  position: absolute;
  top: 50vh;
  left: 40px;
  width: fit-content;
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 3vh;
}

li {
  padding: 10px;
}

.interests {
  padding-top: 40%;
}

.links {
  position: absolute;
  right: 10px;
  margin: 0px;
}

.link {
  display: inline-block;
}

.aboutme {
  position: relative;
  top: 15%;
  width: 50%;
  margin: auto;
}

.contact {
  position: relative;
  top: 30%;
}

.wechat {
  top: 0;
}

.wechat_qr {
  position: relative;
  left: -15px;
  text-align: left;
  margin-top: 5px;
}

a {
  color: white;
}

.dia_member {
  vertical-align: middle;
  margin: 10px;
}

.dia {
  width: 200px;
}

.asofia {
  width: 100px
}

@media only screen and (max-width: 480px) {
  .nav {
    display: none;
  }

  .links {
    position: absolute;
    right: 50px;
    margin: 0px;
  }

  .bm-burger-button {
    display: block;
  }

  .aboutme {
    width: 100%;
    margin: auto;
    text-align: center;
  }
   p{
     font-size: large;
   }

   .masonry__container {
     position: relative;
     top: 50px;
   }

   .dia {
     width: 100px;
   }

   .asofia {
     width: 50px;
   }
}